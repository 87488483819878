import { Controller } from '@hotwired/stimulus'
import { useMutation, UseMutation } from 'stimulus-use'
import { Dropdown } from 'bootstrap'
import EventHandler from 'bootstrap/js/src/dom/event-handler'
import { bindInput, unbindInput, bindEvent, unbindEvent } from '@/libs/binder'
import { parseHTML } from '@/utils/parse'
import { popperArrow, popperSameWidth } from '@/utils/popper'
import { id } from 'date-fns/locale'

const SEARCH_THRESHOLD = 10
const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="dropdown"]:not(.disabled):not(:disabled)'

export default class extends Controller {
    static targets = ['select', 'toggle', 'menu', 'item', 'label', 'search']
    static values = {
        offset: { type: Array, default: [0,2] },
        showArrow: { type: Boolean, default: false },
        centered: { type: Boolean, default: false }
    }

    connect() {
        this.isInitialized = this.data.get('initialized') == 'true'

        if (this.isInitialized) {
            this.removeDropdownNodes()
        }
        else {
            this.data.set('initialized', 'true')
        }
        
        this.options = Array.from(this.selectTarget.options)
        
        if (this.placeholder) {
            this.options = this.options.filter((opt) => opt.value)
        }

        this.selectTarget.style.display = 'none'
        this.element.append(...this.getDropdownNodes())

        this.element.classList.add('dropdown-center')
        this.toggleTarget.setAttribute('data-bs-toggle', 'dropdown')
        this.toggleTarget.disabled = this.selectTarget.disabled

        useMutation(this, { attributes: true, element: this.selectTarget })
        
        this.dropdown = new Dropdown(this.toggleTarget, {
            autoClose: 'outside',
            offset: this.offsetValue,
            popperConfig: (defaultConfig) => {
                defaultConfig.modifiers.push(popperSameWidth())
                
                if (this.showArrowValue) {
                    defaultConfig.modifiers.push(popperArrow({ element: '[data-select-arrow]' }))
                }

                defaultConfig.strategy = 'fixed'
                return defaultConfig
            }
        })

        if (this.hasSearchTarget) {
            bindInput(this, this.searchTarget, 'searchQuery')
            bindEvent(this, this.searchTarget, 'keydown', 'searchKeyDown')

            this.onToggleKeyDown = (e) => { this.toggleKeyDown(e) }
            EventHandler.on(document, 'keydown.bs.dropdown.data-api', SELECTOR_DATA_TOGGLE, this.onToggleKeyDown)
        }

        bindEvent(this, this.element, 'shown.bs.dropdown', 'dropdownShown')
        bindEvent(this, this.element, 'hidden.bs.dropdown', 'dropdownHidden')
        this.dispatch('ready', { detail: { controller: this }})
    }

    disconnect() {
        if (this.hasSearchTarget) {
            unbindInput(this, this.searchTarget, 'searchQuery')
            unbindEvent(this, this.searchTarget, 'keydown', 'searchKeyDown')
            EventHandler.off(document, 'keydown.bs.dropdown.data-api', SELECTOR_DATA_TOGGLE, this.onToggleKeyDown)
        }
        unbindEvent(this, this.element, 'shown.bs.dropdown', 'dropdownShown')
        unbindEvent(this, this.element, 'hidden.bs.dropdown', 'dropdownHidden')
        this.dropdown.dispose()
        this.removeDropdownNodes()
        this.selectTarget.style.removeProperty('display')
    }

    mutate(entries) {
        for (const mutation of entries) {
            if (mutation.attributeName == 'disabled') {
                this.toggleTarget.disabled = this.selectTarget.disabled
            }
        }
    }

    getDropdownNodes() {
        let template
        let itemIndex = 0

        template  = '<div>'
        template +=     `<button type="button" class="form-control${this.selectTarget.classList.contains('is-invalid') ? ' is-invalid' : ''}" data-select-target="toggle"><div data-select-target="label">${this.label}</div></button>`
        template +=     '<div class="dropdown-menu" data-select-target="menu">'
        
        if (this.search)  {
            template +=     `<div class="select-search"><input type="text" class="form-control" data-select-target="search" placeholder="${this.searchPlaceholder}"></div>`
        }
        
        template +=         '<div class="dropdown-menu-panel" tabindex="-1">'

        Array.from(this.selectTarget.children).forEach(child => {
            if (child.tagName == 'OPTGROUP') {
                template +=     `<div class="dropdown-group">`
                template +=         `<div class="dropdown-header">${child.label}</div>`
                
                Array.from(child.children).forEach((opt) => {
                    template = this.appendOptionTemplate(template, opt)
                })

                template +=     '</div>'
            }
            else if (child.tagName == 'OPTION') {
                template = this.appendOptionTemplate(template, child)
            }
        })
        
        template +=         '</div>'
        
        if (this.sjowArrowValue) {
            template +=     '<div data-select-arrow class="popper-arrow"></div>'
        }
        
        template +=     '</div>'
        template += '</div>'

        return parseHTML(template).childNodes
    }

    removeDropdownNodes() {
        if (this.hasToggleTarget) {
            this.toggleTarget.remove()
        }
        if (this.hasMenuTarget) {
            this.menuTarget.remove()
        }
    }

    appendOptionTemplate(template, opt) {
        const idx = this.options.indexOf(opt)
        if (idx > -1) {
            template += `<button class="dropdown-item${opt.selected ? ' selected': ''}" type="button" data-select-target="item" data-action="select#select" data-select-index-param="${idx}">${opt.label}</button>`
        }
        return template
    }

    dropdownShown(e) {
        if (e.target == this.toggleTarget) {
            const item = this.activeItem
            item.scrollIntoView()

            if (this.hasSearchTarget) {
                this.searchTarget.focus()
            }
            else {
                item.focus()
            }
        }
    }

    dropdownHidden(e) {
        if (e.target == this.toggleTarget) {
            if (this.hasSearchTarget) {
                this.searchTarget.value = ''
                this.filterItems()
            }
        }
    }

    select({params: {index}}) {
        const opt = this.options[index]
        
        if (!opt.selected) {
            this.itemTargets.forEach((item, idx) => {
                item.classList.toggle('selected', idx == index)
            })
    
            this.selectTarget.selectedIndex = opt.value
            this.selectTarget.value = opt.value
            
            this.selectTarget.dispatchEvent(new Event('change'))
            this.dispatch('change', {detail: {selected: opt, index: index}})
            this.setLabel(opt.label)
        }

        this.dropdown.hide()

        if (this.postSelectFocus) {
            this.toggleTarget.focus()        
        }
    }

    setValue(e) {
        const index = e.detail.value ? this.options.indexOf(this.options.find(o => o.value == e.detail.value)) : e.detail.index
    
        if (index !== undefined) {
            this.select({params: {index: index}})
        }
    }

    toggleKeyDown(e) {
        if (e.key == 'ArrowDown' && e.target == this.toggleTarget) {
            this.searchTarget.focus()
        }
    }

    searchQueryValueChanged(value, oldValue) {
        this.filterItems(value)
    }

    searchKeyDown(e) {
        if (e.key =='ArrowDown') {
            e.preventDefault()
            const first = this.itemTargets.find(el => el.offsetParent !== null)
            
            if (first) {
                first.focus()
            }
        }
    }

    setLabel(value) {
        this.labelTarget.innerHTML = value
    }

    filterItems(value) {
        value = value ? value.toLowerCase() : value

        this.itemTargets.forEach((item, idx) => {
            const opt = this.options[idx]
            if (value) {
                item.classList.toggle('hide', !opt.label.toLowerCase().includes(value))
            }
            else {
                item.classList.remove('hide')
            }
        })
    }

    get selectedOption() {
        return this.options.find(o => o.selected)
    }

    get activeOption() {
        return this.selectedOption || this.options[0]
    }

    get activeItem() {
        return this.itemTargets[this.options.indexOf(this.activeOption)]
    }

    get label() {
        return this.placeholder && !this.selectedOption ? `<span class="placeholder">${this.placeholder}</span>` : this.activeOption.label
    }

    get search() {
        let value = this.data.get('search'),
            threshold = parseInt(value)
        
        if (value == 'true') {
            return true
        }
        if (value == 'auto') {
            threshold = SEARCH_THRESHOLD
        }

        return threshold && this.options.length > threshold ? true : false
    }

    get placeholder() {
        return this.data.get('placeholder') || this.selectTarget.getAttribute('placeholder')
    }

    get searchPlaceholder() {
        return this.data.get('searchPlaceholder') || ''
    }

    get postSelectFocus() {
        return this.data.get('postSelectFocus') != 'false'
    }

}
